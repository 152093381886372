import {HttpClient} from "@angular/common/http"
import {inject, Injectable} from "@angular/core"
import {BehaviorSubject, Observable, finalize, tap} from "rxjs"
import {CategoryDTO, CategoryRecord, PageRequestDTO, ResponseBody, SubcategoryDTO} from "../models/Entities"
import { ConfigService } from "../core/config.service"


@Injectable({
  providedIn: "root"
})
export class B2bCategoriesService {
  private config = inject(ConfigService);
  private get baseUrl(): string {
    return this.config.getItemsUrl() + "/api/v2/categories";
  }

  private get filesUrl(): string {
    return this.config.getFilesUrl() + "/api/v2/files";
  }

  // categories: CategoryDTO[] = [];
  // loadingCategories: boolean = false;

  // constructor(private httpClient: HttpClient) { }

  // getAllCategories(): Observable<ResponseBody<CategoryRecord[]>> {
  //   return this.httpClient.get<ResponseBody<CategoryRecord[]>>(`${this.baseUrl}`)
  // }

  // getAllCategoriesWithImages(): Observable<ResponseBody<CategoryRecord[]>> {
  //   this.loadingCategories = true;
  //   return this.httpClient.get<ResponseBody<CategoryRecord[]>>(`${this.baseUrl}/images`)
  //     .pipe(
  //       finalize(() => this.loadingCategories = false)
  //     );
  // }

  // getSubcategoriesByCategoryId(category_id: string): Observable<ResponseBody<SubcategoryDTO[]>> {
  //   return this.httpClient.get<ResponseBody<SubcategoryDTO[]>>(`${this.baseUrl}/${category_id}/subcategories`);
  // }

  // getDetailsByCategoryId(category_id: string): Observable<ResponseBody<any>> {
  //   return this.httpClient.get<ResponseBody<any>>(`${this.baseUrl}/${category_id}/details`);
  // }
 // private baseUrl = environment.itemsUrl + "/api/v2/categories"
  categories: CategoryDTO[] = []
  loadingCategories: boolean = false
  categoriesSubject = new BehaviorSubject<any[]>([])
  categories$ = this.categoriesSubject.asObservable()

  paginator: PageRequestDTO = {
    page: 0,
    size: 50
  }

  constructor(private httpClient: HttpClient) {}

  getAllCategories(): Observable<ResponseBody<any>> {
    this.loadingCategories = true
    return this.httpClient.post<ResponseBody<any>>(`${this.baseUrl}/search`, this.paginator).pipe(
      tap((response) => {
        this.categoriesSubject.next(response.payload.content)
        this.categories = response.payload.content
        return response
      }),
      finalize(() => (this.loadingCategories = false))
    )
  }

  getAllCategoriesWithImages(category_id: string): Observable<ResponseBody<any>> {
    this.loadingCategories = true
    return this.httpClient
      .get<ResponseBody<CategoryRecord[]>>(`${this.filesUrl}/images/category/${category_id}/main`)
      .pipe(finalize(() => (this.loadingCategories = false)))
  }

  getCategoryById(category_id: string = ""): Observable<ResponseBody<any>> {
    const DTO = {
      page: this.paginator.page,
      size: this.paginator.size,
      uuid: category_id === 'all' ? undefined : category_id
    }
    return this.httpClient.post<ResponseBody<CategoryDTO>>(`${this.baseUrl}/search`, DTO)
  }

  getSubcategoriesByCategoryId(category_id: string): Observable<ResponseBody<SubcategoryDTO[]>> {
    const dto = {
      page: this.paginator.page,
      size: this.paginator.size,
      categoryUuid: category_id
    }
    return this.httpClient.post<ResponseBody<SubcategoryDTO[]>>(`${this.baseUrl}/subcategories/search`, dto)
  }

  getDetailsByCategoryId(category_id: string): Observable<ResponseBody<any>> {
    return this.httpClient.get<ResponseBody<any>>(`${this.baseUrl}/${category_id}/details`)
  }
}
